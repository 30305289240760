import './Button.scss';

import * as React from 'react';
import classNames from 'classnames';

type ButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

interface Props {
  primary: boolean;
  secondary: boolean;
  wide: boolean;
  link: boolean;
  destructive: boolean;
}

class Button extends React.PureComponent<Props & ButtonProps> {
  static defaultProps = {
    type: 'button',
    primary: false,
    secondary: false,
    wide: false,
    link: false,
    destructive: false
  };

  render() {
    const {
      children,
      primary,
      secondary,
      wide,
      link,
      destructive,
      ...restProps
    } = this.props;

    return (
      <button
        {...restProps}
        className={classNames('Button', {
          'Button--primary': primary,
          'Button--secondary': secondary,
          'Button--wide': wide,
          'Button--link': link,
          'Button--destructive': destructive
        })}
      >
        {children}
      </button>
    );
  }
}

export default Button;
