import './Header.scss';

import * as React from 'react';
import { Link } from 'react-router-dom';

import XOXOLogo from './Logo';

type Props = {
  title: string;
};

const Header: React.FC<Props> = (props) => {
  return (
    <header className="Header">
      <Link to="/" className="Header-logo">
        <XOXOLogo />
      </Link>

      <h1 className="Header-title">{props.title}</h1>
    </header>
  );
};

export default Header;
