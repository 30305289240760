import * as React from 'react';
import { StripeProvider as ExternalStripeProvider } from 'react-stripe-elements';

declare global {
  interface Window {
    Stripe?(apiKey: string): {};
  }
}

interface Props {
  apiKey: string;
  children: React.ReactNode;
}

interface State {
  stripe: {} | null;
}

export class StripeProvider extends React.PureComponent<Props, State> {
  state = {
    stripe: null
  };

  componentDidMount() {
    if (window.Stripe != null) {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({ stripe: window.Stripe(this.props.apiKey) });
    } else {
      const scriptTag = document.querySelector('#stripe-js');

      if (scriptTag != null) {
        scriptTag.addEventListener('load', () => {
          if (window.Stripe != null) {
            this.setState({ stripe: window.Stripe(this.props.apiKey) });
          } else {
            throw new Error('Stripe could not be loaded.');
          }
        });
      } else {
        throw new Error('Stripe script tag is missing from the document.');
      }
    }
  }

  render() {
    return (
      <ExternalStripeProvider stripe={this.state.stripe}>
        {this.props.children}
      </ExternalStripeProvider>
    );
  }
}

export default StripeProvider;
