function clearMessagesDelayedFrom(loadTime: number) {
  if (
    window.ENV.flash !== null &&
    loadTime !== null &&
    new Date().getTime() - loadTime > 1000
  ) {
    window.ENV.flash = null;
  }
}

/**
 * Clear the flash messages in the window object. Has no effect for a short
 * grace period after this is first loaded to prevent client side redirects from
 * dismissing messages immedately.
 */
const clearFlashMessages = clearMessagesDelayedFrom.bind(
  null,
  new Date().getTime()
);

export default clearFlashMessages;
