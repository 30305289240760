import * as React from 'react';

import Section from './section';
import Link from './Link';

const NotFoundMessage: React.FC = () => {
  return (
    <Section.Block title="Page Not Found">
      <Section.Content>
        <p>
          Whoops! This page does not exist. Looks like something went wrong.
        </p>

        <p>
          If you think this is an error, please email{' '}
          <Link to="mailto:hi@xoxofest.com" usePushState={false}>
            hi@xoxofest.com
          </Link>{' '}
          with a brief description of what happened, and we’ll look into it
          right away.
        </p>

        <p>
          You can also <Link to="/account">return to your XOXO account</Link>,
          or <Link to="/">return to our homepage</Link>.
        </p>
      </Section.Content>
    </Section.Block>
  );
};

export default NotFoundMessage;
