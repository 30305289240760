import './Navigation.scss';

import * as React from 'react';
import classnames from 'classnames';
import { Link, useMatch } from 'react-router-dom';

import * as AttendContext from 'components/AttendContext';
import * as Core from 'components/core';

const AccountNavigation: React.FC = () => {
  return (
    <AttendContext.Consumer>
      {({ user, event }) => {
        const isAttendee =
          user && user.currentPass && user.currentPass.status != 'CANCELLED';
        const isAdmin = user && user.role === 'admin';
        const canRegister = event && event.surveysEnabled && !isAttendee;

        return (
          <nav className="AccountNavigation">
            {canRegister && (
              <NavigationLink to="/attend">Register</NavigationLink>
            )}

            {user && (
              <>
                <NavigationLink to="/account" activeOnSubPages={false}>
                  Overview
                </NavigationLink>
                <NavigationLink to="/account/profile">Profile</NavigationLink>
                {isAttendee && (
                  <NavigationLink to="/account/attendance">
                    Attendance
                  </NavigationLink>
                )}
                <NavigationLink to="/account/settings">Settings</NavigationLink>

                {isAttendee && (
                  <NavigationLink to="/account/directory">
                    Directory
                  </NavigationLink>
                )}

                <Core.Rule />

                <NavigationLink to="/account/data-export">
                  Data Export
                </NavigationLink>
              </>
            )}

            {user ? (
              <a href="/auth/logout" className="AccountNavigation-link">
                Log Out
              </a>
            ) : (
              <NavigationLink to="/login">Log In</NavigationLink>
            )}

            {isAdmin && (
              <>
                <Core.Rule />
                <a href="/admin" className="AccountNavigation-link">
                  Admin
                </a>
              </>
            )}
          </nav>
        );
      }}
    </AttendContext.Consumer>
  );
};

const NavigationLink: React.FC<{
  to: string;
  activeOnSubPages?: boolean;
  children: React.ReactNode;
}> = (props) => {
  const { to, children, activeOnSubPages = true } = props;

  const isActive = useMatch(activeOnSubPages ? `${to}/*` : to);

  return (
    <Link
      to={to}
      className={classnames('AccountNavigation-link', {
        'is-active': isActive,
      })}
    >
      {children}
    </Link>
  );
};

export default AccountNavigation;
