import * as React from 'react';
import { Query } from 'react-apollo';

import { CurrentUser_currentUser } from 'components/queries/__generated__/CurrentUser';
import { CurrentEvent_currentEvent } from 'components/queries/__generated__/CurrentEvent';

import {
  CurrentUser,
  CURRENT_USER_QUERY
} from 'components/queries/CurrentUserQuery';

import {
  CurrentEvent,
  CURRENT_EVENT_QUERY
} from 'components/queries/CurrentEventQuery';

const AttendContext = React.createContext<{
  user: CurrentUser_currentUser | null;
  userLoading: boolean;
  event: CurrentEvent_currentEvent | null;
  eventLoading: boolean;
}>({
  user: null,
  userLoading: true,
  event: null,
  eventLoading: true
});

const Provider: React.FC<{
  children: React.ReactNode;
}> = (props) => {
  return (
    <Query<CurrentUser> query={CURRENT_USER_QUERY}>
      {(userQuery) => (
        <Query<CurrentEvent> query={CURRENT_EVENT_QUERY}>
          {(eventQuery) => {
            return (
              <AttendContext.Provider
                value={{
                  user:
                    userQuery.data != null ? userQuery.data.currentUser : null,
                  userLoading: userQuery.loading,
                  event:
                    eventQuery.data != null
                      ? eventQuery.data.currentEvent
                      : null,
                  eventLoading: eventQuery.loading
                }}
              >
                {props.children}
              </AttendContext.Provider>
            );
          }}
        </Query>
      )}
    </Query>
  );
};

const { Consumer } = AttendContext;

export { Provider, Consumer };
