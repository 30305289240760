import 'styles/application.scss';

import * as React from 'react';
import { createRoot } from 'react-dom/client';

import { ApolloProvider } from 'react-apollo';

import apolloClient from 'apolloClient';
import Application from 'components/Application';

const rootEl = document.createElement('div');
document.body.appendChild(rootEl);

const root = createRoot(rootEl);

root.render(
  <ApolloProvider client={apolloClient}>
    <Application />
  </ApolloProvider>,
);
