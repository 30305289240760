import 'styles/application.scss';

import * as React from 'react';
import { Routes, Route, BrowserRouter, useLocation } from 'react-router-dom';

import ErrorBoundary from './ErrorBoundary';
import * as Core from 'components/core';
import clearFlashMessages from 'utils/clearFlashMessages';
import StripeProvider from './StripeProvider';

import * as AttendContext from 'components/AttendContext';
import { Redirect } from './Redirect';

const Login = React.lazy(() => import('components/Login'));
const Account = React.lazy(() => import('components/account/Account'));
const Attend = React.lazy(() => import('components/attend/Attend'));
const TermsOfService = React.lazy(() => import('./TermsOfService'));
const PrivacyPolicy = React.lazy(() => import('./PrivacyPolicy'));

declare global {
  interface Window {
    ENV: {
      stripeApiKey: string;
      flash: { [key: string]: string } | null;
    };
    ga?(command: string, ...fields: string[]): void;
  }
}

const Application: React.FC<{}> = () => {
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <ClearFLashMessages />
        <StripeProvider apiKey={window.ENV.stripeApiKey}>
          <AttendContext.Provider>
            <React.Suspense fallback={<Core.Loader fullPage />}>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/account/*" element={<Account />} />

                <Route path="/attend/*" element={<Attend />} />

                <Route path="/terms" element={<TermsOfService />} />
                <Route path="/privacy" element={<PrivacyPolicy />} />

                <Route path="/" element={<Redirect to="/attend" />} />

                <Route path="*" element={<Core.NotFoundPage />} />
              </Routes>
            </React.Suspense>
          </AttendContext.Provider>
        </StripeProvider>
      </BrowserRouter>
    </ErrorBoundary>
  );
};

export default Application;

const ClearFLashMessages: React.FC = () => {
  const location = useLocation();

  React.useEffect(() => {
    clearFlashMessages();
    if (window.ga) {
      window.ga('send', 'pageview');
    }
  }, [location]);

  return null;
};
