import * as React from 'react';

import NotFoundMessage from './NotFoundMessage';
import Layout from 'components/layout/Layout';

const NotFoundPage: React.FC = () => {
  return (
    <Layout title="404">
      <NotFoundMessage />
    </Layout>
  );
};

export default NotFoundPage;
