import './Link.scss';

import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import classNames from 'classnames';

interface Props {
  to: string;
  usePushState: boolean;
  primaryButton: boolean;
  secondaryButton: boolean;
  wide: boolean;
  children: React.ReactNode;
  color?: 'default' | 'green';
}

class Link extends React.PureComponent<Props> {
  static defaultProps = {
    usePushState: true,
    primaryButton: false,
    secondaryButton: false,
    wide: false,
  };

  render() {
    const {
      to,
      usePushState,
      primaryButton,
      secondaryButton,
      wide,
      children,
      color = 'default',
      ...restProps
    } = this.props;

    const classes = classNames('Link', `Link--color-${color}`, {
      'Link--primaryButton': primaryButton,
      'Link--secondaryButton': secondaryButton,
      'Link--wide': wide,
    });

    if (usePushState) {
      return (
        <RouterLink {...restProps} to={to} className={classes}>
          {children}
        </RouterLink>
      );
    } else {
      return (
        <a {...restProps} href={to} className={classes}>
          {children}
        </a>
      );
    }
  }
}

export default Link;
