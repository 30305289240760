import './Heading.scss';

import * as React from 'react';

type Props = {
  children: React.ReactNode;
  accessory?: React.ReactNode;
  background?: 'grey' | 'none' | 'success' | 'error';
};

const Heading: React.FC<Props> = (props) => {
  const { children, accessory, background } = props;

  return (
    <div className={`BlockHeading BlockHeading--${background}`}>
      <div className="BlockHeading-title">{children}</div>

      {accessory && <div>{accessory}</div>}
    </div>
  );
};

Heading.defaultProps = {
  background: 'grey',
};

export default Heading;
