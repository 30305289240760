import './Notification.scss';

import * as React from 'react';

type Props = {
  type: 'notification' | 'success' | 'error';
  children: React.ReactNode;
};

const Notification: React.FC<Props> = (props) => {
  return (
    <div className={`Notification Notification--${props.type}`}>
      {props.children}
    </div>
  );
};

Notification.defaultProps = {
  type: 'notification'
};

export default Notification;
