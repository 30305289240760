import './Layout.scss';

import * as React from 'react';

import * as Core from 'components/core';
import Header from './Header';
import Footer from './Footer';
import Navigation from './Navigation';

type Props = {
  title: string;
  children: React.ReactNode;
  width?: 'small' | 'medium' | 'large' | 'full';
};

const Layout: React.FC<Props> = (props) => {
  return (
    <div className={`Layout Layout-${props.width}Width`}>
      <Header title={props.title} />
      <div className="Layout-body">
        <div className="Layout-body-navigation">
          <Navigation />
        </div>
        <div className="Layout-body-content">
          <div>
            <Core.FlashMessages />
            {props.children}
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

Layout.defaultProps = {
  width: 'small'
};

export default Layout;
