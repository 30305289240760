import './Input.scss';

import * as React from 'react';
import classnames from 'classnames';

export type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  valid?: boolean;
};

const Input: React.FC<Props> = (props) => {
  const { valid, ...restProps } = props;

  return (
    <input
      {...restProps}
      className={classnames('CoreInput', {
        'is-inValid': !props.valid
      })}
    />
  );
};

Input.defaultProps = {
  type: 'text',
  valid: true
};

export default Input;
