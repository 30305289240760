import './FormGroup.scss';

import * as React from 'react';
import { uniqueId } from 'lodash-es';
import classNames from 'classnames';

export interface FormGroupProps {
  inline?: boolean;
  label?: string;
  required?: boolean;
  hint?: React.ReactNode;
  errorMessage?: string | null;
  subField?: boolean;
  bold?: boolean;
}

interface Props extends FormGroupProps {
  children(options: { inputId: string }): React.ReactNode;
}

class FormGroup extends React.PureComponent<Props> {
  static defaultProps = {
    inline: false,
    required: false,
    subField: false,
  };

  inputId = uniqueId('input');

  render() {
    const {
      inline,
      label,
      required,
      hint,
      errorMessage,
      children,
      subField,
      bold = false,
    } = this.props;

    return (
      <div
        className={classNames('FormGroup', {
          'FormGroup--inline': inline,
          'FormGroup--subField': subField,
          'is-invalid': errorMessage != null,
          'is-bold': bold,
        })}
      >
        {label && (
          <label className="FormGroup-label" htmlFor={this.inputId}>
            {label}
            {required && <span className="FormGroup-required">Required</span>}
          </label>
        )}

        {children({ inputId: this.inputId })}

        {errorMessage != null && (
          <div className="FormGroup-error">{errorMessage}</div>
        )}

        {hint != null && <p className="FormGroup-hint">{hint}</p>}
      </div>
    );
  }
}

export default FormGroup;
