import './Footer.scss';

import * as React from 'react';
import { Query } from 'react-apollo';
import { Link } from 'react-router-dom';

import {
  CurrentUser,
  CURRENT_USER_QUERY,
} from 'components/queries/CurrentUserQuery';

export default class Footer extends React.Component<{}> {
  render() {
    return (
      <nav>
        <Query<CurrentUser> query={CURRENT_USER_QUERY}>
          {({ data, loading, error }) => {
            if (loading) {
              return null;
            } else if (error) {
              return <h1>Error</h1>;
            }

            return (
              <footer className="Footer">
                <div className="Footer-column">
                  <div className="Footer-links">
                    <a
                      className="Footer-links-link"
                      href="https://xoxofest.com/videos"
                    >
                      Videos
                    </a>
                    <a
                      className="Footer-links-link"
                      href="https://xoxofest.com/guide/"
                    >
                      Guide
                    </a>
                    <a
                      className="Footer-links-link"
                      href="https://xoxofest.com/blog/"
                    >
                      Blog
                    </a>
                    <a
                      className="Footer-links-link"
                      href="https://xoxofest.com/guide/about-xoxo/"
                    >
                      About XOXO
                    </a>
                  </div>
                </div>

                <div className="Footer-column">
                  <div className="Footer-links">
                    <a
                      className="Footer-links-link"
                      href="https://xoxofest.com/guide/covid"
                    >
                      COVID
                    </a>
                    <a
                      className="Footer-links-link"
                      href="https://xoxofest.com/guide/conduct"
                    >
                      Code of Conduct
                    </a>

                    <a
                      className="Footer-links-link"
                      href="https://xoxofest.com/guide/accessibility"
                    >
                      Accessibility
                    </a>

                    <a
                      className="Footer-links-link"
                      href="https://xoxofest.com/guide/inclusion"
                    >
                      Inclusion
                    </a>
                  </div>
                </div>

                <div className="Footer-column">
                  <div className="Footer-links">
                    <a
                      className="Footer-links-link"
                      href="mailto:hi@xoxofest.com"
                    >
                      hi@xoxofest.com
                    </a>
                    <a
                      className="Footer-links-link"
                      href="https://xoxo.zone/@xoxo"
                    >
                      Mastodon
                    </a>
                    <a
                      className="Footer-links-link"
                      href="https://bsky.app/profile/xoxofest.com"
                    >
                      Bluesky
                    </a>
                  </div>
                </div>

                <div className="Footer-column">
                  <div className="Footer-links">
                    <Link className="Footer-links-link" to="/terms">
                      Terms of Service
                    </Link>

                    <Link className="Footer-links-link" to="/privacy">
                      Privacy Policy
                    </Link>
                  </div>
                </div>
              </footer>
            );
          }}
        </Query>
      </nav>
    );
  }
}
