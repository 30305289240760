import './Select.scss';

import * as React from 'react';
import classnames from 'classnames';

export type Props = React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
> & {
  valid?: boolean;
};

const Select: React.FC<Props> = (props) => {
  const { valid, ...restProps } = props;

  return (
    <select
      {...restProps}
      className={classnames('CoreSelect', { 'is-inValid': !valid })}
    />
  );
};

Select.defaultProps = {
  valid: true
};

export default Select;
