import './Block.scss';

import * as React from 'react';
import classNames from 'classnames';

import Heading from './Heading';

type Props = {
  children: React.ReactNode;
  title?: string;
  type?: 'grey' | 'success' | 'error';
};

const Block: React.FC<Props> = (props) => {
  const { type = 'grey' } = props;

  return (
    <div className={classNames('Block', `Block--${type}`)}>
      {props.title && (
        <Heading background={type}>
          <h2>{props.title}</h2>
        </Heading>
      )}
      {props.children}
    </div>
  );
};

export default Block;
