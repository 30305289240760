import classNames from 'classnames';
import './Content.scss';

import * as React from 'react';

type Props = {
  children: React.ReactNode;
  type?: 'grey' | 'success' | 'error';
};

const Content: React.FC<Props> = (props) => {
  const { type = 'grey' } = props;

  return (
    <div className={classNames('BlockContent', `BlockContent--${type}`)}>
      {props.children}
    </div>
  );
};

export default Content;
