import * as React from 'react';

import Notification from './Notification';

const railsNotificationsTypes = {
  alert: 'error',
  notice: 'success',
};

/**
 * Renders flash messages generated by Rails. These are rendered into a json
 * object on the window.
 */
export default function FlashMessages() {
  const { flash } = window.ENV;

  if (flash == null || Object.keys(flash).length === 0) {
    return null;
  }

  return (
    <>
      {Object.keys(flash).map((key) => {
        const notificationType = railsNotificationsTypes[key] || 'notification';

        return (
          <Notification key={key} type={notificationType}>
            {flash[key]}
          </Notification>
        );
      })}
    </>
  );
}
